import { FinancialClientInfo } from '../../api/types';
import { Box, Group, useMantineTheme } from '@mantine/core';
import { DetailRow } from '../../components/DetailRow';
import React from 'react';
import MultilineText from '../../components/MultilineText';
import NoPrint from '../../components/NoPrint';
import { useIntl } from 'react-intl';

type Props = {
	clientInfo: FinancialClientInfo;
	forPrint?: boolean;
};

export function FullClientInfo({ clientInfo, forPrint }: Props) {
	const intl = useIntl();
	const theme = useMantineTheme();
	const nameLabel = intl.formatMessage({
		id: 'wiaBJj',
		description: 'Client name label',
		defaultMessage: 'Client',
	});
	const currencyLabel = intl.formatMessage({
		id: 'MvU40K',
		description: 'Client currency label',
		defaultMessage: 'Currency',
	});
	const businessIdLabel = intl.formatMessage({
		id: 'mB+fR0',
		description: 'Client business ID label',
		defaultMessage: 'ID',
	});
	const taxIdLabel = intl.formatMessage({
		id: 'vAwYpH',
		description: 'Client tax ID label',
		defaultMessage: 'Tax ID',
	});
	const addressLabel = intl.formatMessage({
		id: '7XvZZo',
		description: 'Client address label',
		defaultMessage: 'Address',
	});
	const locationLabel = intl.formatMessage({
		id: '6JIBNv',
		description: 'Client location label, whether it is local or international',
		defaultMessage: 'Location',
	});
	return (
		<Group direction="column" spacing={0}>
			<DetailRow label={nameLabel}>
				{clientInfo.name}
			</DetailRow>
			<NoPrint
				disabled={!forPrint}
			>
				<Box>
					<DetailRow
						label={currencyLabel}
						sx={{ fontFamily: theme.other.currencyFont }}
					>
						{clientInfo.currency}
					</DetailRow>
				</Box>
			</NoPrint>
			<NoPrint
				disabled={!forPrint || !clientInfo.international}
			>
				<Box><DetailRow label={businessIdLabel}>{clientInfo.businessId}</DetailRow></Box>
			</NoPrint>
			<NoPrint
				disabled={(!forPrint || !clientInfo.international) && !!clientInfo.taxId}
			>
				<Box><DetailRow label={taxIdLabel}>{clientInfo.taxId || '-'}</DetailRow></Box>
			</NoPrint>
			<DetailRow label={addressLabel} size="sm">
				<MultilineText text={clientInfo.address} />
			</DetailRow>
			<NoPrint
				disabled={!forPrint}
			>
				<Box>
					<DetailRow label={locationLabel}>
						{clientInfo.international ? 'International' : 'Local'}
					</DetailRow>
				</Box>
			</NoPrint>
		</Group>
	);
}
