const config = {
	domain: 'ursadev.eu.auth0.com',
	clientId: process.env.REACT_APP_AUTH_CLIENT_ID as string,
	audience: process.env.REACT_APP_AUTH_AUDIENCE as string,
	scope: 'read:finance write:finance',
	apiPath: process.env.REACT_APP_API_PATH as string,
	api2Path: process.env.REACT_APP_API2_PATH as string,
};

export default config;
