import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AppProviders from './AppProviders';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import config from './config';

ReactDOM.render(
	(
		<React.StrictMode>
			<Auth0Provider
				domain={config.domain}
				clientId={config.clientId}
				audience={config.audience}
				redirectUri={window.location.origin}
				scope={config.scope}
				hd="ursadev.com"
			>
				<AppProviders>
					<App />
				</AppProviders>
			</Auth0Provider>
		</React.StrictMode>
	),
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
